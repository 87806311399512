body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0fafe;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.svg-clickable {
  cursor: pointer;
  fill: #0B597A;
  pointer-events: bounding-box;
  stroke: #0B597A;
  stroke-width: 1px;
}

.leaflet-container {
  height: 400px;
  width: 100%;
  margin: 0 auto;
}
/******* KT *******/
.kt-cls-1,
.kt-cls-13,
.kt-cls-15,
.kt-cls-16,
.kt-cls-45,
.kt-cls-46,
.kt-cls-47,
.kt-cls-48,
.kt-cls-5,
.kt-cls-51,
.kt-cls-53,
.kt-cls-55,
.kt-cls-58,
.kt-cls-59,
.kt-cls-62,
.kt-cls-68,
.kt-cls-69,
.kt-cls-7,
.kt-cls-70,
.kt-cls-71,
.kt-cls-72,
.kt-cls-73,
.kt-cls-8 {
  fill: none;
}

.kt-cls-1,
.kt-cls-10,
.kt-cls-11,
.kt-cls-14,
.kt-cls-15,
.kt-cls-19,
.kt-cls-23,
.kt-cls-5,
.kt-cls-54,
.kt-cls-55,
.kt-cls-56,
.kt-cls-58,
.kt-cls-59,
.kt-cls-6,
.kt-cls-64,
.kt-cls-7,
.kt-cls-9 {
  stroke: #a9a9a9;
}

.kt-cls-1,
.kt-cls-10,
.kt-cls-11,
.kt-cls-13,
.kt-cls-16,
.kt-cls-45,
.kt-cls-46,
.kt-cls-47,
.kt-cls-49,
.kt-cls-5,
.kt-cls-50,
.kt-cls-51,
.kt-cls-52,
.kt-cls-53,
.kt-cls-56,
.kt-cls-57,
.kt-cls-59,
.kt-cls-6,
.kt-cls-62,
.kt-cls-64,
.kt-cls-65,
.kt-cls-66,
.kt-cls-67,
.kt-cls-68,
.kt-cls-69,
.kt-cls-7,
.kt-cls-70,
.kt-cls-71,
.kt-cls-72,
.kt-cls-73,
.kt-cls-8,
.kt-cls-9 {
  stroke-linejoin: round;
}

.kt-cls-1,
.kt-cls-13,
.kt-cls-16 {
  stroke-width: 4px;
}

.kt-cls-17,
.kt-cls-2,
.kt-cls-20,
.kt-cls-22,
.kt-cls-49,
.kt-cls-50,
.kt-cls-65 {
  fill: #01a0c6;
}

.kt-cls-2,
.kt-cls-3,
.kt-cls-4,
.kt-cls-67 {
  fill-rule: evenodd;
}

.kt-cls-12,
.kt-cls-19,
.kt-cls-21,
.kt-cls-23,
.kt-cls-24,
.kt-cls-25,
.kt-cls-26,
.kt-cls-27,
.kt-cls-28,
.kt-cls-29,
.kt-cls-3,
.kt-cls-30,
.kt-cls-31,
.kt-cls-32,
.kt-cls-33,
.kt-cls-34,
.kt-cls-35,
.kt-cls-36,
.kt-cls-37,
.kt-cls-38,
.kt-cls-39,
.kt-cls-40,
.kt-cls-41,
.kt-cls-42,
.kt-cls-43,
.kt-cls-44,
.kt-cls-54,
.kt-cls-57,
.kt-cls-6,
.kt-cls-64,
.kt-cls-9 {
  fill: #a9a9a9;
}

.kt-cls-4,
.kt-cls-56,
.kt-cls-63 {
  fill: #0086a6;
}

.kt-cls-14,
.kt-cls-15,
.kt-cls-45,
.kt-cls-49,
.kt-cls-5,
.kt-cls-50,
.kt-cls-56,
.kt-cls-6,
.kt-cls-71,
.kt-cls-9 {
  stroke-width: 0.99px;
}

.kt-cls-7 {
  stroke-width: 1.7px;
}

.kt-cls-13,
.kt-cls-16,
.kt-cls-20,
.kt-cls-22,
.kt-cls-45,
.kt-cls-46,
.kt-cls-47,
.kt-cls-48,
.kt-cls-49,
.kt-cls-51,
.kt-cls-52,
.kt-cls-53,
.kt-cls-65,
.kt-cls-8 {
  stroke: #01a0c6;
}

.kt-cls-69,
.kt-cls-72,
.kt-cls-8 {
  stroke-width: 2.83px;
}

.kt-cls-13,
.kt-cls-46,
.kt-cls-48,
.kt-cls-72,
.kt-cls-9 {
  stroke-linecap: round;
}

.kt-cls-10,
.kt-cls-11,
.kt-cls-14,
.kt-cls-18,
.kt-cls-52,
.kt-cls-61,
.kt-cls-66,
.kt-cls-67 {
  fill: #fff;
}

.kt-cls-10,
.kt-cls-57 {
  stroke-width: 0.57px;
}

.kt-cls-11,
.kt-cls-68 {
  stroke-width: 0.85px;
}

.kt-cls-14,
.kt-cls-15,
.kt-cls-19,
.kt-cls-22,
.kt-cls-48,
.kt-cls-54,
.kt-cls-55,
.kt-cls-58,
.kt-cls-61 {
  stroke-miterlimit: 10;
}

.kt-cls-20 {
  stroke-width: 0.12px;
}

.kt-cls-21,
.kt-cls-44,
.kt-cls-50,
.kt-cls-57,
.kt-cls-61,
.kt-cls-66,
.kt-cls-67,
.kt-cls-68 {
  stroke: #fff;
}

.kt-cls-21 {
  stroke-width: 0.25px;
}

.kt-cls-22 {
  stroke-width: 0.02px;
}

.kt-cls-23,
.kt-cls-24,
.kt-cls-25,
.kt-cls-26,
.kt-cls-27,
.kt-cls-28,
.kt-cls-29,
.kt-cls-30,
.kt-cls-31,
.kt-cls-32,
.kt-cls-33,
.kt-cls-34,
.kt-cls-35,
.kt-cls-36,
.kt-cls-37,
.kt-cls-38,
.kt-cls-39,
.kt-cls-40,
.kt-cls-41,
.kt-cls-42,
.kt-cls-43,
.kt-cls-44 {
  stroke-width: 0.04px;
}

.kt-cls-24 {
  stroke: #adadad;
}

.kt-cls-25 {
  stroke: #b1b1b1;
}

.kt-cls-26 {
  stroke: #b5b5b5;
}

.kt-cls-27 {
  stroke: #b9b9b9;
}

.kt-cls-28 {
  stroke: #bdbdbd;
}

.kt-cls-29 {
  stroke: #c2c2c2;
}

.kt-cls-30 {
  stroke: #c6c6c6;
}

.kt-cls-31 {
  stroke: #cacaca;
}

.kt-cls-32 {
  stroke: #cecece;
}

.kt-cls-33 {
  stroke: #d2d2d2;
}

.kt-cls-34 {
  stroke: #d6d6d6;
}

.kt-cls-35 {
  stroke: #dadada;
}

.kt-cls-36 {
  stroke: #dedede;
}

.kt-cls-37 {
  stroke: #e2e2e2;
}

.kt-cls-38 {
  stroke: #e6e6e6;
}

.kt-cls-39 {
  stroke: #ebebeb;
}

.kt-cls-40 {
  stroke: #efefef;
}

.kt-cls-41 {
  stroke: #f3f3f3;
}

.kt-cls-42 {
  stroke: #f7f7f7;
}

.kt-cls-43 {
  stroke: #fbfbfb;
}

.kt-cls-46,
.kt-cls-47 {
  stroke-width: 2px;
}

.kt-cls-51,
.kt-cls-58,
.kt-cls-59 {
  stroke-width: 9px;
}

.kt-cls-54 {
  stroke-width: 0.43px;
}

.kt-cls-60 {
  fill: #ddd;
}

.kt-cls-61 {
  stroke-width: 2.83px;
}

.kt-cls-62,
.kt-cls-70,
.kt-cls-72 {
  stroke: #000;
}

.kt-cls-69,
.kt-cls-71 {
  stroke: #0086a6;
}

.kt-cls-70 {
  stroke-width: 2.27px;
}

.kt-cls-73 {
  stroke: #201f1b;
}

.kt-cls-74 {
  fill: #201f1b;
}

/****** LT ******/
.lt-cls-13,
.lt-cls-16,
.lt-cls-45,
.lt-cls-46,
.lt-cls-47,
.lt-cls-5,
.lt-cls-51,
.lt-cls-53,
.lt-cls-59,
.lt-cls-63,
.lt-cls-7,
.lt-cls-71,
.lt-cls-72 {
  fill: none;
}
.lt-cls-10,
.lt-cls-11,
.lt-cls-23,
.lt-cls-5,
.lt-cls-54,
.lt-cls-59,
.lt-cls-6,
.lt-cls-65,
.lt-cls-7 {
  stroke: #a9a9a9;
}
.lt-cls-10,
.lt-cls-11,
.lt-cls-13,
.lt-cls-16,
.lt-cls-45,
.lt-cls-46,
.lt-cls-47,
.lt-cls-5,
.lt-cls-50,
.lt-cls-51,
.lt-cls-52,
.lt-cls-53,
.lt-cls-58,
.lt-cls-6,
.lt-cls-63,
.lt-cls-65,
.lt-cls-7,
.lt-cls-71,
.lt-cls-72 {
  stroke-linejoin: round;
}
.lt-cls-13,
.lt-cls-16 {
  stroke-width: 4px;
}
.lt-cls-17,
.lt-cls-2,
.lt-cls-20,
.lt-cls-22,
.lt-cls-50 {
  fill: #01a0c6;
}
.lt-cls-2,
.lt-cls-3 {
  fill-rule: evenodd;
}
.lt-cls-12,
.lt-cls-23,
.lt-cls-24,
.lt-cls-25,
.lt-cls-26,
.lt-cls-27,
.lt-cls-28,
.lt-cls-29,
.lt-cls-3,
.lt-cls-30,
.lt-cls-31,
.lt-cls-32,
.lt-cls-33,
.lt-cls-34,
.lt-cls-35,
.lt-cls-36,
.lt-cls-37,
.lt-cls-38,
.lt-cls-39,
.lt-cls-40,
.lt-cls-41,
.lt-cls-42,
.lt-cls-43,
.lt-cls-44,
.lt-cls-54,
.lt-cls-58,
.lt-cls-6,
.lt-cls-65 {
  fill: #a9a9a9;
}
.lt-cls-64 {
  fill: #0086a6;
}
.lt-cls-45,
.lt-cls-5,
.lt-cls-50,
.lt-cls-6,
.lt-cls-72 {
  stroke-width: 0.99px;
}
.lt-cls-7 {
  stroke-width: 1.7px;
}
.lt-cls-13,
.lt-cls-16,
.lt-cls-20,
.lt-cls-22,
.lt-cls-45,
.lt-cls-46,
.lt-cls-47,
.lt-cls-51,
.lt-cls-52,
.lt-cls-53 {
  stroke: #01a0c6;
}
.lt-cls-13,
.lt-cls-46 {
  stroke-linecap: round;
}
.lt-cls-10,
.lt-cls-11,
.lt-cls-18,
.lt-cls-52,
.lt-cls-62 {
  fill: #fff;
}
.lt-cls-10,
.lt-cls-58 {
  stroke-width: 0.57px;
}
.lt-cls-11 {
  stroke-width: 0.85px;
}
.lt-cls-22,
.lt-cls-54,
.lt-cls-59,
.lt-cls-62 {
  stroke-miterlimit: 10;
}
.lt-cls-20 {
  stroke-width: 0.12px;
}
.lt-cls-44,
.lt-cls-50,
.lt-cls-58,
.lt-cls-62 {
  stroke: #fff;
}
.lt-cls-22 {
  stroke-width: 0.02px;
}
.lt-cls-23,
.lt-cls-24,
.lt-cls-25,
.lt-cls-26,
.lt-cls-27,
.lt-cls-28,
.lt-cls-29,
.lt-cls-30,
.lt-cls-31,
.lt-cls-32,
.lt-cls-33,
.lt-cls-34,
.lt-cls-35,
.lt-cls-36,
.lt-cls-37,
.lt-cls-38,
.lt-cls-39,
.lt-cls-40,
.lt-cls-41,
.lt-cls-42,
.lt-cls-43,
.lt-cls-44 {
  stroke-width: 0.04px;
}
.lt-cls-24 {
  stroke: #adadad;
}
.lt-cls-25 {
  stroke: #b1b1b1;
}
.lt-cls-26 {
  stroke: #b5b5b5;
}
.lt-cls-27 {
  stroke: #b9b9b9;
}
.lt-cls-28 {
  stroke: #bdbdbd;
}
.lt-cls-29 {
  stroke: #c2c2c2;
}
.lt-cls-30 {
  stroke: #c6c6c6;
}
.lt-cls-31 {
  stroke: #cacaca;
}
.lt-cls-32 {
  stroke: #cecece;
}
.lt-cls-33 {
  stroke: #d2d2d2;
}
.lt-cls-34 {
  stroke: #d6d6d6;
}
.lt-cls-35 {
  stroke: #dadada;
}
.lt-cls-36 {
  stroke: #dedede;
}
.lt-cls-37 {
  stroke: #e2e2e2;
}
.lt-cls-38 {
  stroke: #e6e6e6;
}
.lt-cls-39 {
  stroke: #ebebeb;
}
.lt-cls-40 {
  stroke: #efefef;
}
.lt-cls-41 {
  stroke: #f3f3f3;
}
.lt-cls-42 {
  stroke: #f7f7f7;
}
.lt-cls-43 {
  stroke: #fbfbfb;
}
.lt-cls-46,
.lt-cls-47 {
  stroke-width: 2px;
}
.lt-cls-51,
.lt-cls-59 {
  stroke-width: 9px;
}
.lt-cls-54 {
  stroke-width: 0.43px;
}
.lt-cls-61 {
  fill: #ddd;
}
.lt-cls-62 {
  stroke-width: 2.83px;
}
.lt-cls-63,
.lt-cls-71 {
  stroke: #000;
}
.lt-cls-72 {
  stroke: #0086a6;
}
.lt-cls-71 {
  stroke-width: 2.27px;
}
